
import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatQuotePickupDestinationText } from '@/utils/string'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'

@Component({})
export default class BookingHeader extends Vue {
  @Prop({ type: Object, required: true }) readonly quote: QuoteDetailV2
  @Prop({ type: String, required: false }) readonly featuredBidCompanyName: string

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get title(): string {
    return formatQuotePickupDestinationText(this.quote, this.$t('common.TO'))
  }
}
